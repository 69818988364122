import React from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/BlogDetails.css"

const BlogDetails = () => {
  const loc = useLocation();
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    const [month, day, year] = formattedDate.split(' ');
    return `${month.toUpperCase()} ${day.replace(',', '')}, ${year}`;
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={loc.state.img}
          style={{ width: "70%", height: "300px", objectFit: "cover" }}
          alt=""
        />
      </div>
      <br />
      <div style={{width:"70%", margin:'auto', textAlign:'justify'}}>
        <div className="itlte-part-bg">
          <h3>{loc.state.title}</h3>
          <div className="d-flex align-items-center" style={{gap:"15px"}}>
            <p>{formatDate(loc.state.date)}</p>
            <p>{loc.state.author}</p>
          </div>
        </div>
        <br />
        <div>{loc.state.description}</div>
      </div><br /><br />
    </div>
  );
};

export default BlogDetails;
