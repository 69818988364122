import React, { useState } from "react";
import "../../assets/styles/Product.css";
import pr1 from "../../assets/images/oss1.jpeg";
import pr2 from "../../assets/images/oss2.jpeg";
import pr3 from "../../assets/images/oss3.jpeg";
import pr4 from "../../assets/images/oss4.jpeg";
import pr5 from "../../assets/images/oss5.jpeg";
import pr6 from "../../assets/images/oss6.jpeg";
import pr7 from "../../assets/images/oss7.jpeg";
import pr8 from "../../assets/images/oss8.jpeg";
import g0 from "../../assets/images/ol0.jpeg";
import g1 from "../../assets/images/ol1.jpg";
import g2 from "../../assets/images/ol2.jpg";
import g3 from "../../assets/images/ol3.jpg";
import bulb from "../../assets/images/bulb.png";
import sun from "../../assets/images/sun.png";
import rem from "../../assets/images/rem.png";
import { GoArrowRight } from "react-icons/go";
import w1 from "../../assets/images/ol4.jpg";
import w2 from "../../assets/images/ol5.jpg";
import w3 from "../../assets/images/ol6.jpg";
import w4 from "../../assets/images/ol7.jpg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import pr4w from "../../assets/images/ent.jpg";

const Relaxing = () => {
  const nav = useNavigate();
  const [career, setCareer] = useState("Outdoor Solutions");
  const options = [
    {
      name: "Movie theaters",
      nav: "/product/Entertainment_&_Lifestyle/movie-theatre#product",
    },
    {
      name: "Music",
      nav: "/product/Entertainment_&_Lifestyle/music#product",
    },
    {
      name: "Golf Simulation",
      nav: "/product/Entertainment_&_Lifestyle/golf-simulation#product",
    },
    {
      name: "Gaming",
      nav: "/product/Entertainment_&_Lifestyle/gaming#product",
    },
    {
      name: "Outdoor Solutions",
      nav: "/product/Entertainment_&_Lifestyle/outdoor-solution#product",
    },
  ];
  const prodList = [
    {
      img: pr4,
      txt: "Bowers & Wilkins",
    },
    {
      img: pr2,
      txt: "Wisdom",
    },
    {
      img: pr3,
      txt: "Sonance",
    }
  ];
  return (
    <div className="product-section">
      <div className="wall-prod" style={{backgroundImage:`url(${pr4w})`}}>
        <div>
          <h1>Outdoor Solutions</h1>
          <h4>Entertainment & Lifestyle</h4>
        </div>
      </div>
      <div className="filters">
        {options.map((e, i) => (
          <HashLink
            to={e.nav}
            style={{
              color: e.name === career ? "white" : "#282866",
              textDecoration: "none",
            }}
          >
            <button
              onClick={() => {
                setCareer(e.name);
                nav(e.nav);
              }}
              key={i}
              className="career-btn-filter py-2"
              style={{
                background: e.name === career ? "#282866" : "white",
                color: e.name === career ? "white" : "#282866",
              }}
            >
              {e.name}
            </button>
          </HashLink>
        ))}
      </div>
      <div className="prod-list">
        {prodList.map((e, i) => (
          <div className="prods-img" style={{ textAlign: "center" }} key={i}>
            <img src={e.img} alt="" style={{ borderRadius: "50%", height: "150px", width: "150px", objectFit:"cover" }}/>
          </div>
        ))}
      </div>
      <div id="product">
        <div id="carouselExampleIndicators" className="carousel slide">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            >
              <div className="dot-caro" style={{ color: "green" }}></div>
            </button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row caro-lr-1">
                <div className="caro-left col-lg-6 d-flex justify-content-center">
                  <div className="img-trans img-trans-2">
                  <div>
                    <img src={pr1} className="mb-3" height={440} width={530} style={{borderRadius:'12px', objectFit:"cover"}} alt="" />
                  </div>
                  </div>
                </div>
                <div className="caro-right col-lg-6">
                  <h4>Bowers & Wilkins</h4>
                  <p>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s.
                  </p>
                  <div className="d-flex">
                    <img className="mx-3" src={sun} height={40} alt="" />
                    <div>
                      <strong>Lorem Ipsum </strong>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <img
                      className=""
                      style={{ marginLeft: "20px", marginRight: "22px" }}
                      src={bulb}
                      height={40}
                      alt=""
                    />
                    <div>
                      <strong>Lorem Ipsum </strong>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <img className="mx-3" src={rem} height={40} alt="" />
                    <div>
                      <strong>Lorem Ipsum </strong>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </div>
                  </div>
                  <button
                    className="btn-caro-1-1"
                    onClick={() => nav("/consultation")}
                  >
                    REQUEST A QUOTE
                  </button>
                  {/* <button className="btn-caro-2-1 mx-2">
                  GET A QUOTE <GoArrowRight />
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="caro-blogs">
        <p
          style={{ textAlign: "center", color: "#757575", fontSize: "1.1rem" }}
        >
          Our Products
        </p>
        <h4 style={{ textAlign: "center" }}>Speakers</h4>
        <br />
        <div className="row-blog-prod">
          <div className="mx-auto" style={{ textAlign: "center" }}>
            <img src={pr5} height={300} width={300} style={{objectFit:'cover'}} alt="" />
            <div
              className="my-3"
              style={{ fontSize: "0.9rem", fontWeight: "600" }}
            >
              
            </div>
            <div style={{ fontSize: "0.9rem", width: "300px", margin: "auto" }}>
             
            </div>
          </div>
          <div className="mx-auto" style={{ textAlign: "center" }}>
            <img src={pr6} height={300} width={300} style={{objectFit:'cover'}} alt="" />
            <div
              className="my-3"
              style={{ fontSize: "0.9rem", fontWeight: "600" }}
            >
              
            </div>
            <div style={{ fontSize: "0.9rem", width: "300px", margin: "auto" }}>
              
            </div>
          </div>
          <div className="mx-auto" style={{ textAlign: "center" }}>
            <img src={pr7} height={300} width={300} style={{objectFit:'cover'}} alt="" />
            <div
              className="my-3"
              style={{ fontSize: "0.9rem", fontWeight: "600" }}
            >
            
            </div>
            <div style={{ fontSize: "0.9rem", width: "300px", margin: "auto" }}>
              
            </div>
          </div>
          <div className="mx-auto" style={{ textAlign: "center" }}>
            <img src={pr8} height={300} width={300} style={{objectFit:'cover'}} alt="" />
            <div
              className="my-3"
              style={{ fontSize: "0.9rem", fontWeight: "600" }}
            >
              
            </div>
            <div style={{ fontSize: "0.9rem", width: "300px", margin: "auto" }}>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Relaxing;
